<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <b-card :header="'Contract - ' + title" class="mt-4">
          <div v-if="saving">
            <b-spinner /> Opslaan...
          </div>
          
          <b-form v-if="!saving" @submit.prevent="onSubmit">
            <form-field v-for="(field, name) in form.fields" :field="field" :key="name" :form="form" :name="name" /> 

            <b-button type="submit" variant="primary">Opslaan</b-button>
          </b-form>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  import FormField from '@/components/FormField'

  export default {
    name: "ContractDetailEdit",
    components: {
      FormField,
    },
    data() {
      return {
        saving: false,
        title: '',
        form: {
          values: null,
          validated: false,
          fields: {
            'Name': {
              label: 'Naam',
              type: 'text',
              required: true,
            },
            'Company': {
              label: 'Bedrijf',
              type: 'text',
            },
            "Address": {
              label: "Adres",
              type: "text"
            },
            "Postcode": {
              label: "Postcode",
              type: "text"
            },
            "City": {
              label: "Plaats",
              type: "text"
            },
            "Country": {
              label: "Land",
              type: "select",
              options: this.$root.countryOptions
            },
            "Tel": {
              label: "Tel",
              type: "text"
            },
            "Fax": {
              label: "Fax",
              type: "text"
            },
            "Website": {
              label: "Website",
              type: "text"
            },
            "Mailreport": {
              label: "Mailreport",
              required: true,
              type: "email"
            },
            "Status": {
              label: "Status",
              type: "select",
              required: true,
              options: [
                { value: 'active', text: 'Actief' },
                { value: 'inactive', text: 'Inactief' },
                { value: 'deleted', text: 'Verwijderd' },
              ]
            },
            "IsLabel": {
              label: "Is Label",
              type: "select",
              required: true,
              options: [
                { value: 0, text: "Nee" },
                { value: 1, text: "Ja" },
              ]
            },
            "IsPortal": {
              label: "Is Portal",
              type: "select",
              required: true,
              options: [
                { value: 0, text: "Nee" },
                { value: 1, text: "Ja" },
              ]
            },
            "BankNumber": {
              label: "Bank rekeningnummer",
              type: "text"
            },
            "BankName": {
              label: "Bank naam",
              type: "text"
            },
            "BankCity": {
              label: "Bank Plaats",
              type: "text"
            },
            "BankCountry": {
              label: "Bank Land",
              type: "select",
              options: this.$root.countryOptions
            },
            "Iban": {
              label: "IBAN",
              type: "text"
            },
            "Bic": {
              label: "BIC",
              type: "text"
            },
            "Btw": {
              label: "BTW Nr.",
              type: "text"
            },
            "Kvk": {
              label: "KvK",
              type: "text"
            }
          }
        }
      };
    },
    props: {
      contract: Object,
    },
    watch: {
    },
    methods: {
      onSubmit () {
        this.saving = true

        if (this.contract) {
          this.$http.put('contracts/'+this.contract.ContractID, this.form.values)
            .then((response) => {
              this.$emit('refresh') 
              this.$router.push({ name: 'contract-detail', params: { id: response.data.ContractID } })
            })
            .catch((error) => {
              console.log(error)
            });

        } else {
          this.$http.post('contracts', this.form.values)
            .then((response) => {
              this.$emit('refresh')
              this.$router.push({ name: 'contract-detail', params: { id: response.data.ContractID } })
            })
            .catch((error) => {
              console.log(error)
            });
        }
      }
    },
    mounted() {

      if (this.contract == null) {
        this.title = 'Nieuw'
        this.$set(this.form, 'values', {})
      } else {
        this.title = this.contract.Name
        this.$set(this.form, 'values', {...this.contract})
      }

      this.$root.$data.crumbs = [
        { to: { name: "contract-search" }, text: "Contracten" },
        { text: this.title },
      ];
    }
  };
</script>

<style lang="scss" scoped>
.track {
  .card-header {
    color: black;
    background-color: #acbcc8;
  }
}
</style>